@tailwind base;
@tailwind components;
@tailwind utilities;

@import './_variables.scss';
@import './_reset.scss';
@import './_buttons.scss';
@import './_app.scss';
@import './_header.scss';
@import './_hero.scss';
@import './_whyChoose.scss';
@import './_safe.scss';
@import './_getStarted.scss';
@import './_footer.scss';
@import './_mobileMenu.scss';
@import './_modal.scss';
@import './_legal.scss';
@import './juno/_juno.scss';
@import './juno/_client.scss';
@import './juno/_junoam.scss';
@import './404.scss';
@import './_home.scss';
@import './_footer-old.scss';
@import './_login&signup.scss';
@import './_deposit.scss';
@import './_myAccount.scss';
@import './_tools.scss';
@import './_theme.scss';
@import './_b2c_header.scss';
@import './_b2c_signup.scss';
@import './_b2c_wallet';
@import './_enable_disable_authenticator';
@import './_b2c_settings';
@import './_onboard_kyc.scss';
@import './_b2c_add-address';
@import './_faqs.scss';
@import './_faqDetail.scss';
@import './juno/client/_addressDetails.scss';
@import './notFound.scss';
@import './_emailVerify.scss';

.paysuB2c {
    background: #fff;
    color: #1e1e1e;

    .paysu-btn-transparent {
        color: var(--btn-background);
    }

    .section-title {
        color: #1e1e1e;
        font-family: var(--titleFont);
    }

    .section-upperTitle p {
        color: #9e1f63;
        border-color: #e7dcd4;
        font-family: var(--titleFont);
    }

    .ps-section-title {
        font-weight: 600;
    }

    .ps-section-text,
    .ps-section-grey-text,
    .paysu-steps-text,
    .section-text,
    .paysu-contact-value,
    .ps-label,
    .ps-input,
    .ps-textarea,
    .ps-footer-text {
        font-family: var(--textFont);
    }

    .header {
        background: #fff;

        .section-border {
            background: rgba(0, 0, 0, 0.16);
        }

        .paysu-btn-transparent,
        .paysu-btn {
            padding-top: 12px;
            padding-bottom: 12px;

            @media screen and (max-width: 440px) {
                font-size: 16px;
                padding: 7px 24px;
            }
        }

        .theme-btn-red {
            background: #c7c7c7;

            &:hover {
                background: #c7c7c7;
            }
        }
    }

    .header__profile_name {
        color: #1e1e1e;
        line-height: 115%;
    }

    .header__profile {
        background: #f6f6f6;
        font-family: var(--textFont);
        margin-left: 20px;
        border: 1px solid rgba(0, 0, 0, 0.16);
        height: 44px;
    }

    .header__profile_icon {
        background: var(--btn-background);
        line-height: 1;
    }

    &-buyNow {
        border-radius: 99px;
    }

    .header__profile_menu,
    .mobileMenu {
        background: #fff;
        backdrop-filter: blur(0);
        border-color: rgba(0, 0, 0, 0.16);
    }

    .theme-switch-wrapper {
        display: none;
    }

    .header__profile_item {
        color: #1e1e1e;

        &:hover {
            color: var(--btn-background);
            background: transparent;
        }
    }

    .header__mobileBtn div {
        background: #1e1e1e;
    }
}

.psB2cHero {
    background: #f6f6f6;

    .theme-btn-block {
        margin-top: 40px;
    }

    .ps-section-title {
        font-size: 64px;
    }

    .ps-section-text {
        font-size: 20px;
    }

    .ps-section-grid {
        align-items: center;
    }
}

.psB2cFaq {
    background: #f6f6f6;

    .paysu-intouch {
        background: #fff;
    }
}

.psB2cSafe {
    background: #f6f6f6;
}

.psB2cHeader {
    &-layout {
        height: 76px;

        @media screen and (max-width: 1058px) {
            height: 61px;
        }
    }
}
.support-junomoney-email {
    color: #0C66E4;
    text-decoration: none;
}

.support-junomoney-email:hover {
    text-decoration: underline;
}

@media screen and (max-width: 1550px) {
    .psB2cHero .ps-section-title {
        font-size: 42px;
    }

    .psB2cHero .ps-section-text {
        font-size: 18px;
    }
}

@media screen and (max-width: 991px) {
    .psB2cHero .ps-section-title {
        font-size: 36px;
    }

    .psB2cHero .ps-section-text {
        font-size: 16px;
    }

    .psB2cHero .theme-btn-block {
        margin-top: 24px;
    }

    .psB2cHero {
        .ps-section-img {
            max-width: 600px;
            margin: 0 auto;
        }
    }
}

@media screen and (max-width: 440px) {
    .psB2cHero .ps-section-title {
        font-size: 27px;
    }
}


.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

.hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.basic-multi-select{



.searchble_multi_select__control {
    border-radius: 6px !important;
    height: fit-content;
    min-height: 45px;
    outline: none;
    &:hover{
        border-color: #b3b3b3;
        box-shadow:0 0 0 0 #b3b3b3;
        outline: none;
    }

    .searchble_multi_select__multi-value {
        background-color: transparent;
    }

    .searchble_multi_select__indicator-separator {
        display: none;
    }
    .searchble_multi_select__placeholder{
        color: #000000de;
    }
    .searchble_multi_select__input{
        font-size: 18px;
        line-height: 41px;
        margin-bottom: 0 !important;
    }
    .searchble_multi_select__input-container{
        font-size: 18px;
        line-height: 36px;
        margin: 0;
    }
    
}
}