.jncBalanceCard {
	font-size: 48px;
	font-weight: 600;

	&__curr {
		display: grid;
		grid-template-columns: 1fr 70px;
		grid-gap: 10px;

		&-img {

			svg,
			img {
				width: 100%;
				height: auto;
			}
		}

		&-value {
			font-size: inherit;
			font-weight: 700;
			line-height: 100%;

			span {
				color: #D0D5DD;
			}

			sup {
				top: -1.5em;
				color: #344054;
				font-weight: 400;
				font-size: 16px;
				vertical-align: middle;
			}
		}

		&-percent {
			margin: 16px 0 32px;
			margin-top: 16px;
			font-size: 18px;
		}
	}

	&__actions {
		display: flex;
		align-items: center;

		&-item {
			&:not(:last-child) {
				margin-right: 10px;
			}
		}
	}
}

.jncManageModal {
	margin-top: 24px;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 10px;

	&__curr {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border-radius: 12px;
		border: 1px solid transparent;
		cursor: pointer;
		background: #F2F4F7;
		padding: 15px 10px 10px;
		color: #000;
		position: relative;

		svg {
			position: absolute;
			top: 0;
			right: 0;
			height: 22px;
			width: auto;
		}

		&.is-default {
			pointer-events: none;
			border-color: #D0D5DD;
		}

		&.is-active {
			border-color: #32D583;
		}
		
		img {
			width: 48px;
			height: 48px;
			margin-bottom: 10px;
			border-radius: 50%;
		}

		p {
			font-size: 14px;
			text-align: center;
		}
	}
}

.jnc-btn-disable {
	cursor: not-allowed;
}

@media screen and (max-width: 1815px) {
	.jncBalanceCard {
		font-size: 48px;
	}
}

@media screen and (max-width: 1646px) {
	.jncBalanceCard {
		font-size: 48px;
	}

	.jncBalanceCard__curr {
		grid-template-columns: 1fr 60px;
	}
}

@media screen and (max-width: 1450px) {
	.jncBalanceCard__curr-value sup {
		font-size: 18px;
	}
}

@media screen and (max-width: 1400px) {
	.jncCardsGrid {
		//todo:kris to another file
		grid-template-columns: repeat(2, 1fr);
	}
}

@media screen and (max-width: 1100px) {
	.jncBalanceCard__curr-value span {
		font-size: 1em;
	}
}

@media screen and (max-width: 991px) {
	.jncCardsGrid {
		//todo:kris to another file
		grid-template-columns: 1fr;
		grid-gap: 24px;
	}

	.jncBalanceCard__curr {
		grid-template-columns: auto 1fr;
		align-items: center;
	}

	.jncBalanceCard__actions {
		display: none;
	}

	.jncBalanceCard__curr-img {
		order: -1;
		display: flex;
		align-items: center;

		img {
			width: 40px;
			height: auto;
			flex-shrink: 0;
			flex-grow: 0;
		}

		p {
			font-size: 14px;
			font-weight: 400;
			margin-left: 10px;
		}
	}

	.jncBalanceCard__curr-percent {
		display: none;
	}

	.jncBalanceCard__curr-value {
		text-align: right;
		font-size: 14px;
		font-weight: 400;
	}
}

@media screen and (max-width: 500px) {
	.jncManageModal {
		grid-template-columns: repeat(3, 1fr);
	}
}

@media screen and (max-width: 390px) {
	.jncManageModal__curr img {
		width: 36px;
		height: 36px;
	}
}

@media screen and (max-width: 345px) {
	.jncManageModal {
		grid-template-columns: repeat(2, 1fr);
	}
}