.jncProfile {
	background: #fff;
	border-radius: 12px;
	padding: 24px;
	margin-bottom: 34px;
	&__block {
		margin-bottom: 34px;
		.jncSectionTitle {
			margin-bottom: 0;
		}
	}
	&__header {
		margin-bottom: 26px;
	}
	.savedAccounts {
		max-width: 400px;
		margin-bottom: 34px;
	}
	&__fields {
		label {
			margin-left: 0;
			margin-right: 0;
			width: 100%;
		}
	}
	&__field {
		display: grid;
		grid-template-columns: 200px 1fr;
		grid-gap: 30px;
		align-items: center;
		&:not(.no-border) {
			padding-bottom: 16px;
			margin-bottom: 16px;
			border-bottom: 1px solid #D0D5DD;
		}
		&.no-border-mobile {
			@media screen and (max-width: 767px) {
				border-bottom: 0 !important;
				margin-bottom: 0 !important;  
				padding-bottom: 0 !important; 
			}
		}
		.jncInputView, .jncInput {
			max-width: 450px;
		}
		&-dob {
			.jncTable-inputs-group {
				max-width: 450px;
			}
		}
	}
	&__dob {
		text-align: left;
    padding-right: 36px;
	}
	&__user {
		display: grid;
		grid-template-columns: auto 1fr;
		grid-gap: 14px;
		align-items: center;
		margin-bottom: 12px;
		&-icon {
			width: 60px;
			height: 60px;
			background: #6941C6;
			font-size: 24px;
			font-weight: 500;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			color: #fff;
			text-transform: uppercase;
		}
		&-name {
			font-size: 16px;
			font-weight: 500;
		}
		&-number {
			font-size: 16px;
			color: #475467;
		}
	}
	&__actions {
		&-btn {
			padding: 18px 0;
			position: relative;
			text-align: left;
			display: block;
			width: 100%;
			color: #000;
			font-size: 16px;
			&.switch-grid {
				display: grid;
				grid-template-columns: 1fr auto;
				grid-gap: 15px;
				align-items: center;
			}
			label, label span {
				margin: 0;
			}
			label {
				width: auto;
			}
			a {
				color: #000 !important;
			}
			&:before {
				content: "";
				position: absolute;
				height: 1px;
				width: calc(100% + 24px);
				background: #D0D5DD;
				left: -12px;
				bottom: 0;
			}
			&.logout-btn {
				color: #0091FF;
				width: max-content;
				&:before {
					display: none;
				}
			}
		}
	}
	&__subheader {
		text-align: left;
    	padding-top: 16px;
		padding-bottom: 16px;
	}
	&__subfield {
		display: grid;
		grid-template-columns: 540px 1fr;
		grid-gap: 66px;
		align-items: center;
		color: #475467;
		&:not(.no-border) {
			padding-bottom: 16px;
			margin-bottom: 16px;
			border-bottom: 1px solid #D0D5DD;
		}
		&.no-border-mobile {
			@media screen and (max-width: 767px) {
				border-bottom: 0 !important;
				margin-bottom: 0 !important;  
				padding-bottom: 0 !important; 
			}
		}
		.jncInputView, .jncInput {
			max-width: 450px;
		}
		&-dob {
			.jncTable-inputs-group {
				max-width: 450px;
			}
		}
	}
	
}

.jncProfileModal {
	.jncModal__field-currency {
		margin-bottom: 50px !important;
	}
}

.jncProfileModalBody {
	height: 350px !important;
}

.jncProfileFieldBorder {
	border-bottom: 0 !important;
	grid-template-columns: 130px 1fr !important;
}

.jncProfileOpenSansFont {
	font-family: var(--opensansFont);
}

.jncProfile__field {
	font-family: var(--opensansFont);
}

@media screen and (max-width: 991px) {
	.jncProfile__field .jncInputView, .jncProfile__field .jncInput, 
	.jncProfile__field-dob .jncTable-inputs-group {
		max-width: 100%;
	}
}

@media screen and (max-width: 767px) {
	.jncProfile {
		padding: 0;
		border-radius: 0;
	}
	.jncProfile__field {
		grid-template-columns: 1fr;
		grid-gap: 10px;
		
	}
	.jncProfile__field:not(.no-border) {
		border-bottom: 0;
		padding-bottom: 0;
		margin-bottom: 32px;
	}
}

@media screen and (max-width: 550px) {
	.jncProfile__actions-btn {
		font-size: 14px;
	}
	
}